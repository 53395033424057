<template>
  <div class="home-page">
    <header class="header">
      <div class="header-content">
        <h1>Pembemavi Kafeterya</h1>
      </div>
      <div v-if="1==2" class="search-bar">
        <i class="fas fa-search"></i>
        <input type="text" placeholder="Ürün Arama" />
      </div>
      <br>
      <div class="scrolling-menu">
        <div class="menu-item" v-for="item in menuItems" :key="item.PIN_STGRUPANA">
          <span @click.prevent="kategoriDetayAc(item.PIN_STGRUPANA,item.GRUPANA )">{{ item.GRUPANA }}</span>
        </div>
      </div>
    </header>
    <ModalComp :isVisible="isModalVisible" >
          <p>Erişim Yalnızca QR Kod Üzerinden Yapılabilmektedir !</p>
    </ModalComp>
    <div v-for="group in groups" :key="group.PIN_STGRUPANA" class="group-section">
      <div class="group-header">
        <h2>{{ group.GRUPANA }}</h2>
        <a href="#" class="view-all" @click.prevent="kategoriDetayAc(group.PIN_STGRUPANA,group.GRUPANA)">Tümü</a>
      </div>
      <div class="scrolling-menu">
        <div class="menu-item" v-for="item in group.items" :key="item.name" @click="handleClick(item)">
          <img :src="item.imageUrl" alt="coffee" />
          <div class="item-details">
            <h3>{{ item.STOKADI }}</h3>
            <div class="price">{{ item.STSATISFIYAT1 }} ₺</div>
            <div class="price">{{ item.ADET }} adet</div>
            <div v-if="xadmin==0" class="button-group">
              <button class="minus-button" @click="urunAzalt(item.SSTOKID)"><i class="fas fa-minus"></i></button>
              <button class="add-button" @click="urunEkle(item.SSTOKID)"><i class="fas fa-plus"></i></button>
            </div>
            <div v-if="xadmin==1" class="button-group">
              <button class="upload-button" @click="showImageModal(item.SSTOKID)">
                <i class="fas fa-file-upload"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer v-if="!isModalVisible" class="footer">
      <div class="footer-item" v-for="link in footerLinks" :key="link.text" @click="handleFooterClick(link)">
        <i :class="link.icon"></i>
        <span>{{ link.text }}</span>
      </div>
    </footer>
  </div>
  <!-- Görsel Modal Başladı -->
    <div v-if="showImageModalFlag" class="custom-modal">
        <div class="custom-modal-content">
        <span class="close" @click="closeImageModal">&times;</span>
            <img :src="modalImageSrc" alt="Image Preview" class="img-fluid">
            <hr>
            <input type="file" @change="handleFileUpload">
            <hr>
            <div class="file-buttons">
            <button @click="uploadFile">Dosya Yükle</button>
            <hr>
            <button @click="deleteFile">Dosya Sil</button>
            </div>
        </div>
    </div>
  <!-- Görsel Modal Bitti -->
</template>

<script>
import ModalComp from '@/components/ModalComp.vue';
export default {
  name: 'HomePage',
  components: {
    ModalComp
  },
  created() {
    if(this.$route.query.MASAID == undefined)
    {
      if(this.$route.query.ADMIN != undefined)
      {
      // console.log('ADMIN');
        this.xadmin = 1;
        this.getMenuler();
        this.getMenulerAnaSayfa();
      }else
      {
        this.showModal();
      }
      
    }else
    {
        this.xadmin = 0;
        this.xmasaid = this.$route.query.MASAID;
        this.masaSession();
        sessionStorage.setItem('MASAID_SESSION', this.xmasaid);
        this.getMasa();
        this.getMenuler();
      
      
    }
    
  },
  data() {
    return {
        xsstokDosya :null,
        showImageModalFlag: false,
        modalImageSrc: '',
        selectedFile: null,
        isModalVisible:false,
        xmasauuid:null,
        xmasaid:null,
        xadmin:null,
        menuItems: [
        { img: require('../assets/coffee.png'), text: 'All' },
        { img: require('../assets/coffee.png'), text: 'Popular' },
        { img: require('../assets/coffee.png'), text: 'Milk Tea' },
        { img: require('../assets/coffee.png'), text: 'Espresso' },
        { img: require('../assets/coffee.png'), text: 'Latte' },
        { img: require('../assets/coffee.png'), text: 'Mocha' },
      ],
      groups: [
        {
          name: 'Most Popular',
          items: [
            {
              img: require('../assets/coffee.png'),
              name: 'Dalgona Coffee',
              rating: '4.8',
              reviews: 125,
              time: '16 min',
              price: '$10.48'
            },
            {
              img: require('../assets/coffee.png'),
              name: 'Cappuccino',
              rating: '4.8',
              reviews: 125,
              time: '16 min',
              price: '$10.48'
            }
          ]
        }
        // Diğer grupları buraya ekleyebilirsiniz
      ],
      footerLinks: [
        { icon: 'fas fa-shopping-cart', text: 'Sepet' },
      ]
    };
  },
  methods: {
    getImageName(imageSrc) {
        console.log(imageSrc);
       // return imageSrc.split('/').pop(); // Dosya adını URL'den alır
    },
    kategoriDetayAc(_PIN_STGRUPANA,_GRUPADI)
    {
        sessionStorage.setItem('SECILENGRUP',_GRUPADI);
        this.$router.push('/menu-detay?PIN_STGRUPANA=' + _PIN_STGRUPANA);
      
    },
    async urunEkle(_SSTOKID) {
      let formData = new FormData();
      formData.append('SSTOKID', _SSTOKID);
      formData.append('MASAID', this.xmasaid);
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('urunEkle.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response.data.data);
          this.getMenulerAnaSayfa();
        });
    },
    async urunAzalt(_SSTOKID)
    {
      let formData = new FormData();
      formData.append('SSTOKID', _SSTOKID);
      formData.append('MASAID', this.xmasaid);
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('urunAzalt.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log(response.data.data);
        this.getMenulerAnaSayfa();
      });
    },
    async masaSession() {
      let formData = new FormData();
      formData.append('ID', this.xmasaid);
      await this.$axios.post('getMasaSession.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.xmasauuid = response.data.data[0].UUID;
          sessionStorage.setItem('MASA_UUID', this.xmasauuid);
          this.getMenulerAnaSayfa();
        });
    },
    async getMasa() {
      let formData = new FormData();
      formData.append('ID', this.xmasaid);
      await this.$axios.post('getMasa.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.xmasaadi = response.data.data[0].MASAADI;
        });
    },
    async getMenuler() {
      let formData = new FormData();
      await this.$axios.post('getMenuler.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.menuItems = [];
          this.menuItems = response.data.data;
        });
    },
    async getMenulerAnaSayfa() {
      let formData = new FormData();
      formData.append('UUID', this.xmasauuid);
      await this.$axios.post('getMenulerAnaSayfa.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.groups = [];
          this.groups = response.data.data;
        });
    },
    showModal() {
      this.isModalVisible = true;
    },
    handleClick(item) {
      console.log(`Clicked on: ${item.name}`);
      // Bu kısımda istediğiniz işlemi yapabilirsiniz
    },
    viewAll(group) {
      console.log(`View all clicked for: ${group.name}`);
      // Bu kısımda tüm öğeleri göstermek veya başka bir sayfaya yönlendirmek için kod ekleyebilirsiniz
      this.$router.push({ name: 'MenuDetay', params: { category: group.name } });
    },
    handleFooterClick(link) {
      console.log(`Footer link clicked: ${link.text}`);
      // Bu kısımda footer link tıklama işlemini yapabilirsiniz
      if(link.text == "Sepet"){
        //this.$router.push( 'SepetPage');
        this.$router.push({ name: 'SepetPage' });
      }
    },
    showImageModal(_SSTOKID) {
      sessionStorage.setItem('SSTOKID',_SSTOKID);
      this.showImageModalFlag = true;
      this.gorselGetir();
    },
    async gorselGetir() {
        let formData = new FormData();
        formData.append('SSTOKID', sessionStorage.getItem('SSTOKID'));
        await this.$axios.post('getGorsel.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          const data = response.data.data;
          const recordCount = data.length;
          if (recordCount > 0) {
            this.xsstokDosya = response.data.data[0].EVRAKYOL; 
            //this.modalImageSrc = 'http://pembemavicafe.com/'+this.xsstokDosya; 
            this.modalImageSrc = 'http://localhost:2359/'+this.xsstokDosya; 
          } else {
             //this.modalImageSrc = 'http://localhost:2359/urungorsel/coffea-lg-1.jpg'; 
             this.modalImageSrc = 'https://pembemavicafe.com/api/food/coffea-lg-1.jpg'; 
          }
          
          console.log(this.modalImageSrc);
        });
    },
    closeImageModal() {
      this.showImageModalFlag = false;
    },
    handleFileUpload(event) {
        this.selectedFile = event.target.files[0];
    },
    async uploadFile() {
        console.log('SSTOKID :'+sessionStorage.getItem('SSTOKID'));
        let formData = new FormData();
        formData.append('DOSYA', this.selectedFile);
        formData.append('SSTOKID', sessionStorage.getItem('SSTOKID'));
        await this.$axios.post('dosyaKaydet.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response.data.data);
          this.gorselGetir();
        });
    },
    async deleteFile() {
        console.log('SSTOKID :'+sessionStorage.getItem('SSTOKID'));
        let formData = new FormData();
        formData.append('SSTOKID', sessionStorage.getItem('SSTOKID'));
        formData.append('DOSYA', this.xsstokDosya);
        await this.$axios.post('dosyaSil.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response.data.data);
          this.gorselGetir();
        });
    },
  }
};
</script>

<style scoped>

.file-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.file-buttons button {
  background-color: #524fa1;
  color: #FFFFFF;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.file-buttons button:hover {
  background-color: #f78da7;
  transform: scale(1.05);
}

.file-buttons button:active {
  transform: scale(1);
}

.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.upload-button {
  background-color: #524fa1;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  width: 100px;
  height: 30px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background-color: #f78da7;
}

.upload-button i {
  margin-right: 5px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header h1 {
  font-size: 24px;
  color: #2c3e50;
}

.menu-icon {
  font-size: 24px;
  color: #2c3e50;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  margin-top: 20px;
}

.search-bar i {
  font-size: 20px;
  margin-right: 10px;
  color: #888;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  flex-grow: 1;
}

.search-bar .fa-sliders-h {
  font-size: 20px;
  color: #888;
  margin-left: 10px;
}

.group-section {
  margin-top: 20px;
  padding: 0 20px;
}

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  
}

.group-header h2 {
  font-size: 20px;
  color: #524fa1;
}

.group-header h2:hover  {
  font-size: 20px;
  color: #f78da7;
}

.view-all {
  font-size: 14px;
  color: #524fa1;
  text-decoration: none;
  cursor: pointer;
}

.view-all:hover {
  font-size: 14px;
  color: #f78da7;
  text-decoration: none;
  cursor: pointer;
}

.scrolling-menu {
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.menu-item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  min-width: 150px;
  color: #524fa1;
}

.menu-item:hover {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  min-width: 150px;
  color: #f78da7;
}

.menu-item:hover {
  transform: scale(1.05);
}

.menu-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.item-details {
  margin-top: 10px;
}

.item-details h3 {
  font-size: 16px;
  color: #2c3e50;
}

.rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  color: #888;
}

.stars {
  margin-right: 5px;
}

.reviews {
  margin-right: 10px;
}

.price {
  font-size: 18px;
  color: #2c3e50;
  margin-top: 5px;
}

.add-button,
.minus-button {
  background-color: #524fa1;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;
  transition: background-color 0.2s;
}

.add-button:hover,
.minus-button:hover {
  background-color: #f78da7;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #524fa1;
  cursor: pointer;
}

.footer-item i {
  font-size: 24px;
  margin-bottom: 5px;
}

.footer-item:hover {
  color: #f78da7;
}
</style>
