import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import MenuDetay from '../views/MenuDetay.vue'
import SepetPage from '../views/SepetPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/menu-detay',
    name: 'MenuDetay',
    component: MenuDetay
  },
  {
    path: '/sepetpage',
    name: 'SepetPage',
    component: SepetPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
