import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css'  // Bu satırı ekledik
import 'bootstrap/dist/css/bootstrap.min.css'  // Bootstrap CSS
import 'bootstrap'  // Bootstrap JS
axios.defaults.baseURL = '/api'
//axios.defaults.baseURL = 'http://localhost:2359/kafeterya_api2/'

// Axios'u Vue instance'ına global olarak ekliyoruz
const app = createApp(App)
app.config.globalProperties.$axios = axios

app.use(router).mount('#app')
