<template>
  <div class="menu-detay">
    <header class="header">
      <div class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
      </div>
      <h1>{{ this.secilengrup }}</h1>
    </header>
    <div class="items-grid">
      <div class="menu-item" v-for="item in items" :key="item.SSTOKID" @click="handleClick(item)">
        <img :src="item.imageUrl" alt="item.name" />
        <div class="item-details">
          <h3>{{ item.STOKADI }}</h3>
          <div class="price">{{ item.STSATISFIYAT1 }} ₺</div>
          <div class="price">{{ item.ADET }} adet</div>
          <div class="button-group">
              <button class="minus-button" @click="urunAzalt(item.SSTOKID)"><i class="fas fa-minus"></i></button>
              <button class="add-button" @click="urunEkle(item.SSTOKID)"><i class="fas fa-plus"></i></button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuDetay',
  created() {
      if(this.$route.query.PIN_STGRUPANA != null)
      {
        this.xaramagrup = this.$route.query.PIN_STGRUPANA;
        this.getAramaGrup();
      }else
      {
        this.xarama = this.$route.query.SEARCH;
        this.searchQuery = this.xarama;
        this.getArama();
      }
      this.secilengrup = sessionStorage.getItem('SECILENGRUP');
      
  },
  data() {
    return {
      secilengrup:null,
      xarama:null,
      xaramagrup:null,
      searchQuery:null,  
      category: 'Coffee', // Bu değeri dinamik olarak ayarlayabilirsiniz
      items: [
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48',
          GRUPANA:null,
        },
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48'
        },
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48'
        },
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48'
        },
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48'
        },
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48'
        },
        {
          img: require('../assets/coffee.png'),
          name: 'Cappuccino',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          originalPrice: '$12.48'
        },
        // Diğer item'ları buraya ekleyebilirsiniz
      ]
    };
  },
  methods: {
    async getAramaGrup() {
      let formData = new FormData();
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      formData.append('ARAMAGRUP', this.xaramagrup);
      await this.$axios.post('getAramaGrup.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.items = [];
          this.items = response.data.data[0].items;
        });
    },
    async getArama() {
      let formData = new FormData();
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      formData.append('ARAMA', this.xarama);
      await this.$axios.post('getArama.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.items = [];
          this.items = response.data.data[0].items;
          this.isLoading = false;
        });
    },
    async urunAzalt(_SSTOKID)
    {
      let formData = new FormData();
      formData.append('SSTOKID', _SSTOKID);
      formData.append('MASAID', sessionStorage.getItem('MASAID_SESSION'));
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('urunAzalt.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log(response.data.data);
        if(this.$route.query.PIN_STGRUPANA != null)
      {
        this.xaramagrup = this.$route.query.PIN_STGRUPANA;
        this.getAramaGrup();
      }else
      {
        this.xarama = this.$route.query.SEARCH;
        this.searchQuery = this.xarama;
        this.getArama();
      }
      });
    },
    async urunEkle(_SSTOKID) {
      let formData = new FormData();
      formData.append('SSTOKID', _SSTOKID);
      formData.append('MASAID', sessionStorage.getItem('MASAID_SESSION'));
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('urunEkle.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response.data.data);
          if(this.$route.query.PIN_STGRUPANA != null)
      {
        this.xaramagrup = this.$route.query.PIN_STGRUPANA;
        this.getAramaGrup();
      }else
      {
        this.xarama = this.$route.query.SEARCH;
        this.searchQuery = this.xarama;
        this.getArama();
      }
        });
    },
    handleClick(item) {
      console.log(`Clicked on: ${item.name}`);
      // Bu kısımda istediğiniz işlemi yapabilirsiniz
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.menu-detay {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
}

.header h1 {
  font-size: 24px;
  color: #2c3e50;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.menu-item:hover {
  transform: scale(1.05);
}

.menu-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.item-details {
  margin-top: 10px;
}

.item-details h3 {
  font-size: 16px;
  color: #2c3e50;
}

.rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  color: #888;
}

.stars {
  margin-right: 5px;
}

.reviews {
  margin-right: 10px;
}

.time {
  margin-left: auto;
}

.price {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 18px;
  color: #2c3e50;
}

.price .original {
  text-decoration: line-through;
  margin-left: 10px;
  font-size: 14px;
  color: #888;
}

.add-button,
.minus-button {
  background-color: #524fa1;
  color: #FFFFFF;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;
  transition: background-color 0.2s;
}

.add-button:hover,
.minus-button:hover {
  background-color: #f78da7;
}
</style>
