<template>
  <div class="cart">
    <header class="header">
      <div class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
      </div>
      <h1>Sepet</h1>
      <div class="menu-icon">
      </div>
    </header>
    <div class="cart-items">
      <div class="cart-item" v-for="item in items" :key="item.ID">
        <img :src="item.imageUrl" alt="item.name" />
        <div class="item-details">
          <h3>{{ item.STOKADI }} - {{ item.ADET }} adet</h3>
          <div class="price">{{ item.FIYAT }} ₺ - {{ item.TOPLAMTUTAR }} ₺</div>
        </div>
        <div class="quantity-control">
          <button @click="urunAzalt(item.SSTOKID)"><i class="fas fa-minus"></i></button>
          <span>{{ item.quantity }}</span>
          <button @click="urunEkle(item.SSTOKID)"><i class="fas fa-plus"></i></button>
        </div>
      </div>
    </div>
    <div class="cart-summary">
      <div class="subtotal">
        <span>Toplam:</span>
        <span>{{ this.sepetTotal }} ₺</span>
      </div>
      <button class="checkout-button" @click="completeOrder()">Siparişi Tamamla</button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  name: 'SepetPage',
  created() 
  {
    this.getSepet();
    this.getSepetTotal();
  },
  data() {
    return {
      sepetTotal:null,
      items: [
        {
          img: require('../assets/coffee.png'),
          name: 'Ice Chocolate Coffee',
          rating: '4.8',
          reviews: 125,
          time: '16 min',
          price: '$10.48',
          quantity: 1
        },
        // Diğer item'ları buraya ekleyebilirsiniz
      ]
    };
  },
  methods: {
    async siparisiTamamla() {
      let formData = new FormData();
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      console.log('Masa UUID : '+sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('siparisiTamamla.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response.data.data);
        });
    },
    completeOrder() {
      Swal.fire({
        title: 'Siparişiniz tamamlandı!',
        text: 'Siparişiniz başarıyla tamamlandı.',
        icon: 'success',
        confirmButtonText: 'Tamam'
      }).then(() => {
        this.siparisiTamamla();
        this.$router.push('/?MASAID=' + sessionStorage.getItem('MASAID_SESSION'));
      });
    },
    async getSepet() {
      console.log('MASAUUID : '+sessionStorage.getItem('MASA_UUID'));
      let formData = new FormData();
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('getSepet.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.items = [];
          this.items = response.data.data;
        });
    },
    async getSepetTotal() {
      let formData = new FormData();
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('getSepetTotal.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          this.sepetTotal = response.data.data[0].TOPLAMTUTAR;
        });
    },
    async urunAzalt(_SSTOKID)
    {
      let formData = new FormData();
      formData.append('SSTOKID', _SSTOKID);
      formData.append('MASAID', sessionStorage.getItem('MASAID_SESSION'));
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('urunAzalt.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log(response.data.data);
        this.getSepet();
        this.getSepetTotal();
      });
    },
    async urunEkle(_SSTOKID) {
      let formData = new FormData();
      formData.append('SSTOKID', _SSTOKID);
      formData.append('MASAID', sessionStorage.getItem('MASAID_SESSION'));
      formData.append('UUID', sessionStorage.getItem('MASA_UUID'));
      await this.$axios.post('urunEkle.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response.data.data);
          this.getSepet();
         this.getSepetTotal();
        });
    },
    increaseQuantity(item) {
      item.quantity++;
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.cart {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.back-button, .menu-icon {
  cursor: pointer;
  font-size: 24px;
}

.header h1 {
  font-size: 24px;
  color: #2c3e50;
}

.cart-items {
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.cart-item img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
}

.item-details {
  flex-grow: 1;
}

.item-details h3 {
  font-size: 16px;
  color: #2c3e50;
  margin: 0;
}

.rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
  margin: 5px 0;
}

.stars {
  margin-right: 5px;
}

.reviews {
  margin-right: 10px;
}

.price {
  font-size: 18px;
  color: #2c3e50;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  background-color: #524fa1;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.2s;
}

.quantity-control button:hover {
  background-color: #f78da7;
}

.cart-summary {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-summary div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.checkout-button {
  background-color: #524fa1;
  color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.checkout-button:hover {
  background-color: #f78da7;
}
</style>
